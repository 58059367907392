/**
 *
 * Asynchronously loads the component for LoggingDashboard views
 *
 */

 import loadable from 'utils/loadable'

 const LoadableLoggingView = loadable(() => import('./LoggingView'))

 export { LoadableLoggingView as LoggingView }
 